@import "~bootstrap/scss/functions";

// new colors
$blood: #660000;
$zickle-skin: #acb005;
$magor-blue: #465775;
$magor-purple: #b7aec1;
$army: #555e31;
$pickle: rgb(25, 90, 25);
$altarmy: #4d5e1c;
$events: #dbbf82;
$team: #746964;

$custom-theme-colors: (
  "blood": $blood,
  "zickleskin": $zickle-skin,
  "magorblue": $magor-blue,
  "magorpurple": $magor-purple,
  "army": $army,
  "pickle": $pickle,
  "altarmy": $altarmy,
  "events": $events,
  "team": $team,
);

@import "~bootstrap/scss/variables";

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop(
  $utilities-colors,
  rgba-css-var,
  "$key",
  "text"
);
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
