@import url("https://fonts.googleapis.com/css2?family=Schoolbell&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&display=swap");

@font-face {
  font-family: "Armalite Rifle";
  src: url(./fonts/Armalite_Rifle/ArmaliteRifle-Regular.woff) format("woff");
}

@font-face {
  font-family: "Creepster";
  src: url(./fonts/Creepster/Creepster-Regular.woff) format("woff");
}

@font-face {
  font-family: "Stampwriter";
  src: url(./fonts/Stampwriter_Kit/STAMPWRITER-KIT.woff) format("woff");
}

@font-face {
  font-family: "Thank You Stamp";
  src: url(./fonts/KG_Thank_You_Stamp/KgThankYouStamp-M9Wn.woff) format("woff");
}

@font-face {
  font-family: "Old Stamper";
  src: url(./fonts/Old_Stamper/old_stamper.woff) format("woff");
}
