@import "styles/fonts";
@import "styles/variables";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: "Open Sans", sans-serif;
  background-color: $zickle-skin;
  background-image: url(images/zickleskin.svg);
  background-attachment: fixed;
}

body::before {
  content: "";
  display: block;
  padding-top: 5rem;
}

h1 {
  font-family: "Creepster", sans-serif;
  font-size: 2.8rem;
}

h2 {
  font-family: "Armalite Rifle", sans-serif;
  font-size: 2.1rem;
}

h3 {
  font-family: "Schoolbell", cursive;
  font-size: 1.7rem;
  text-transform: uppercase;
}

h4 {
  font-family: "Schoolbell", cursive;
  font-size: 1.7rem;
}

.clear {
  clear: both;
}

.divider {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.text-transparent {
  color: rgba(0, 0, 0, 0);
}

.divider.bottom {
  margin-bottom: -1px !important;
}

.resource-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 4px solid rgba(71, 87, 117, 0.4);
}

header .divider {
  margin-top: -3px !important;
}

svg {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

// Navbar
.app-navbar {
  min-height: 5rem;

  .navbar-brand {
    font-family: "Armalite Rifle", sans-serif;
    font-size: 1.8rem;
  }

  .navbar-nav {
    font-family: "Creepster", sans-serif;
    font-size: 1.6rem;
  }

  .main-nav {
    li {
      padding-left: 0.3rem;
    }
  }

  .social-nav {
    font-size: 2.1rem;
  }
}

// Landing Section
.landing-section {
  .container {
    max-width: 1000px;
  }

  p {
    font-size: 1.5rem;
    font-family: "Schoolbell", cursive;
  }

  img {
    max-width: 360px;
  }
}

.featured-item {
  padding: 0 10%;
  height: 40rem;

  p {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .featured-item {
    height: 30rem;
  }
}

@media screen and (max-width: 468px) {
  .featured-item {
    padding: 0;
  }
}

// Game Section
.game-section {
  background-image: linear-gradient($army, #696e46);
  color: $light;

  .marker-highlight {
    background-color: $magor-purple;
    color: $dark;
    display: inline-block;
    padding: 10px;
  }

  .section-intro {
    position: relative;
    color: $light;
    border: 3px solid #4e5431;
    border-left: none;
    border-right: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(images/camo.svg);
      background-size: cover;
      background-repeat: no-repeat;
      filter: grayscale(50%) opacity(20%);
    }

    .container {
      position: relative;
      min-height: 200px;
    }

    h3 {
      color: $light;
      text-transform: none;
      font-size: 1.9rem;
    }

    .image-container {
      background: $dark;
      max-width: 280px;
      max-height: 280px;
      padding: 15px;
      border: 3px dotted $zickle-skin;
    }

    .image-container img {
      width: 50%;
    }

    .lead {
      font-size: 1.2rem;
    }

    .lead-caption {
      font-size: 1rem;
      font-weight: bold;
      color: $light;
    }
  }

  .build-army {
    img {
      max-width: 250px;
    }
  }

  .nft-uses {
    background: rgba(255, 255, 255, 0);
    color: $light;
    border: none;

    img {
      max-width: 190px;
    }
  }
}

// Magor Section
.magor-section {
  background-image: linear-gradient($magor-purple, $magor-blue);

  h3 {
    font-size: 2rem;
    text-transform: none;
  }

  .mining-pass {
    .coupon {
      position: relative;
      border: 4px dashed $magor-blue;
      max-width: 900px;

      .caption {
        position: absolute;
        bottom: 40%;
        right: -80px;
        font-size: 0.9rem;
        transform: rotate(-90deg);
        color: $magor-blue;
        font-style: italic;
      }
    }

    @media screen and (max-width: 767px) {
      .coupon img {
        width: 45% !important;
      }
    }
    @media screen and (max-width: 575px) {
      .coupon img {
        width: 65% !important;
      }
    }
  }

  .resources-info {
    .container {
      max-width: 950px;
    }

    .card {
      max-width: 275px;
    }
  }

  .mining-rules {
    border-left: none;
    border-right: none;
    border: 5px solid #9fa11d;
    border-right: none;
    border-left: none;
    h3 {
      font-size: 2rem;
    }

    ul {
      list-style: none;
      padding: 0px;
      margin-left: 40px;
    }

    ul li {
      font-weight: bold;
    }

    ul li:before {
      content: "\2692";
      font-size: 1.7rem;
      color: $blood;
      margin: 0 0.8em;
      margin-left: -40px;
    }

    img {
      max-width: 200px;
    }
  }

  .nft-uses {
    h3 {
      font-family: "Armalite Rifle", sans-serif;
      font-size: 2rem;
    }

    h4 {
      font-family: "Schoolbell", cursive;
    }
    @media screen and (max-width: 767px) {
      img {
        width: 40%;
      }
    }
  }
}

.event-section {
  .section-intro {
    background: #8b6f63 !important;
    color: $light;
    border: 2px solid $dark;
    border-right: none;
    border-left: none;

    .section-title {
      text-transform: none;
    }
    .section-image {
      max-width: 250px;
      margin-bottom: -90px;
    }

    .lead {
      font-size: 1.2rem;
    }
  }

  .events {
    .event-date {
      background: $magor-blue;
      color: $light;
      text-align: center;
      width: 5rem;
      height: 5rem;
      font-size: 1.2rem;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.6rem;
      text-transform: uppercase;
    }

    .event {
      border-bottom: 2px dashed $dark;

      .event-img {
        max-width: 175px;
      }

      .event-detail li {
        margin: 7px 0;
      }

      .instructions {
        background: #f8e0ad;
        padding: 5px 10px;
      }
      .note {
        font-style: italic;
      }
    }
  }

  .event:last-of-type {
    border-bottom: none;
  }
}

.team-section {
  color: #e1d6ee;

  .intro-section {
    border: 2px solid $dark;
    border-left: none;
    border-right: none;
    background-color: $zickle-skin;
    background-image: url(images/zickleskin.svg);
    background-attachment: fixed;
    color: $dark;

    h3 {
      text-transform: none;
      font-size: 1.8rem;
    }

    img {
      max-width: 130px;
    }

    .lead {
      font-size: 1.1rem;
    }
  }

  @media screen and (min-width: 992px) {
    .intro-section {
      background-image: url(./images/spectator.png), url(images/zickleskin.svg);
      background-position: 104% 50%, 0% 0%;
      background-size: auto 70%, auto;
      background-repeat: no-repeat, repeat;
      background-attachment: local, fixed;
    }
  }

  .team-zickle {
    h3 {
      font-family: "Old Stamper";
      font-size: 2.1rem;
      letter-spacing: 0.8rem;
      margin: 0 auto;
    }
  }
}

.profile {
  width: 350px;
  font-family: "Stampwriter", monospace;
  font-size: 0.8rem;
  line-height: 2rem;
  color: $dark;
  z-index: 1;

  .card-image {
    min-height: 265px;
  }

  .profile-name {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
  }
}

.site-footer {
  .dim {
    opacity: 0.8;
  }

  .brand {
    font-family: "Armalite Rifle", sans-serif;
  }

  a {
    opacity: 0.8;
    color: $light;
    text-decoration: none;
  }

  a:hover {
    opacity: 1;
    color: $light;
  }

  .social-nav {
    font-size: 2.3rem;
    background-color: #4e0000;
  }

  .copyright {
    background-color: $dark;
    font-size: 0.9rem;
    padding: 15px 0;

    p {
      padding: 0;
      margin: 0;
    }
  }
}
